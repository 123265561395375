@tailwind base;
@tailwind components;
@tailwind utilities;

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

@font-face {
  font-family: "BebasNeue";
  src: url(assets/fonts/BebasNeue/BebasNeue-Regular.ttf);
}

@font-face {
  font-family: "SourceSansPro";
  src: url(assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf);
}

h1 {
  @apply text-[64px] leading-[64px] font-normal font-bebasNeue;
}

h2 {
  @apply text-[40px] leading-10 font-normal font-bebasNeue;
}

h3 {
  @apply text-[32px] leading-[38px] font-normal font-bebasNeue;
}

h4 {
  @apply text-[27px] leading-[27px] font-normal font-bebasNeue;
}

h5 {
  @apply text-2xl leading-[29px] font-normal font-bebasNeue;
}

h6 {
  @apply text-xl leading-8 font-medium font-bebasNeue;
}

button {
  @apply py-[10px] px-6 rounded-[10px] font-normal text-lg leading-[22.63px] font-sourceSans;
}

a {
  @apply hover:text-inherit;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"],
input[type="time"],
textarea {
  @apply bg-dun-0.5 border border-dun-5 rounded-lg font-sourceSans py-[10px] pl-[10px] font-normal text-base text-gray-7 leading-[17px] placeholder:text-gray-2 focus:text-base focus:bg-dun-3 focus:text-gray-5 focus:border-0 focus:ring-dun-3;
}

@layer components {
  .form-checkbox {
    @apply bg-dun-2 border border-dun-5 text-dun-2 rounded-[3px] checked:bg-check focus:ring-0;
  }
  .form-radio {
    @apply border border-red-5;
  }

}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "SourceSansPro";
  scroll-behavior: smooth;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-spin-dot-item {
  background-color: #e54228 !important;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

.ant-input {
  @apply bg-dun-0.5 border border-dun-5 rounded-lg font-sourceSans py-[10px] pl-[10px] font-normal text-base leading-[17px] text-gray-2 placeholder:text-gray-2 focus:text-base focus:bg-dun-3 focus:text-gray-5 focus:border-0 focus:ring-dun-3;
}